(function(window) {
  'use strict';

  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   *
   * @param  {Number}    wait       Timer
   * @param  {Boolean}   immediate  Launch the function immediately
   * @param  {Function}  func       The function that needs debounce
   * @return {Function}             A function to bind to the event debounced
   */
  function debounce(wait, immediate, func) {
    var timeout;

    return function() {
      var context = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // Expose globally
  window.debounce = debounce;
})(window);
